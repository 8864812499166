<template>
    <BT-Blades
        v-if="locationSelected"
        :anchorBlades="[{ bladeName: 'courier-movements' }]">
        <template v-slot="bladesData">
            <BT-Blade-List
                addBladeName="courier-movement"
                bladeName="courier-movements"
                :bladesData="bladesData"
                :canClose="false"
                dense
                :getParams="() => { return { filterBy: 'Flagged,With No Journeys', departureLocationID: locationID } }"
                navigation="courier-movements"
                :refreshToggle="refreshToggle"
                title="Flagged Deliveries">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.bundleName }}{{ item.bundleName != null ? ' - ' : null }}{{ item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ item.dueArrivalOn | toDayShortDate }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item-content>
                </template>
            </BT-Blade-List>
            <BT-Blade-Item
                @fetched="movementLoaded"
                :bladesData="bladesData"
                bladeName="courier-movement"
                :canDelete="false"
                :canEdit="false"
                :canMinimize="false"
                :canPin="false"
                navigation="courier-movement"
                title="Delivery"
                :defaultBladeWidth="400">
                <template v-slot="{ item }">
                    <v-list-item dense>
                        <v-list-item-avatar>
                            <v-img :src="companyLogoURL(item.buyerID)" class="mx-auto">
                                <template v-slot:placeholder>
                                    <v-icon color="primary" >mdi-account</v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-title>
                            {{ item.buyer.companyName }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.deliveredOn != null" dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Delivered On</v-list-item-subtitle>
                            <v-list-item-title>{{ item.deliveredOn | toShortDateAndTime }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Signature-Overlay
                                v-model="item.proofOfDelivery"
                                buttonClass="primary"
                                :canEdit="false"
                                color="white"
                                text="Proof" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="item.deliveredByUserID != null" dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Delivered By</v-list-item-subtitle>
                            <v-list-item-title>
                                <BT-Entity
                                    navigation="users"
                                    itemText="userName"
                                    :itemValue="item.deliveredByUserID" />
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <BT-Field-String
                        v-model="item.driverNotes"
                        label="Driver Notes" />

                    <v-list-item v-if="!item.isFulfilled && (item.isPickup && item.releasedOn != null || item.deliveredOn != null)">
                        <v-list-item-content>
                            <BT-Blade-Button
                                label="Manually Fulfill"
                                @click="fulfill(item, bladesData)"
                                icon="mdi-check-circle-outline" />
                        </v-list-item-content>
                    </v-list-item>

                    <BT-List-Endless
                        v-if="isLengthyArray(item.packagedItems)"
                        :canSearch="false"
                        :dividers="false"
                        :items="item.packagedItems"
                        label="Packages">
                        <template v-slot="{ item }">
                            <v-list-item-avatar>{{ item.quantityDelivered | toDisplayNumber }}</v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <BT-Entity
                                        navigation="measurements"
                                        :itemValue="item.measurementID"
                                        itemText="measurementName" />
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </BT-List-Endless>
                    <BT-List-Endless
                        @loaded="cList => { consignmentsLoaded(bladesData, cList); }"
                        :canSearch="false"
                        :customURL="'/get/GetConsignments/' + item.id"
                        :dividers="false"
                        :getParams="() => { return { includeDetails: false }; }"
                        label="Consignments"
                        navigation="releases"
                        :refreshToggle="refreshConsignments">
                        <template v-slot:listItem="{ item }">
                            <v-list-item @click="bladesData.openBlade({ bladeName: 'stock-consignment', data: { id: item.id } })">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.destinationLocation | toFamiliarLocationLine }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-row class="ma-0 pa-0" dense>
                                            #{{ item.consignmentNumber }}
                                            <v-spacer />
                                            {{ item.orderWeight | toDisplayNumber }} KG
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-List-Endless>
                </template>
            </BT-Blade-Item>
            <Stock-Consignment-Blade :bladesData="bladesData" />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Delivery-Review-Hub',
    components: {
        BTSignatureOverlay: () => import('~components/BT-Signature-Overlay.vue'),
        StockConsignmentBlade: () => import("~home/stock-consignments/Stock-Consignment-Blade.vue")
        // BTTable: () => import('~components/BT-Table.vue')
        // BTSignatureOverlay: () => import('~components/BT-Signature-Overlay.vue')
        // MovementBlade: () => import('~home/movements/Movement-Blade.vue')
    },
    data: function() {
        return {
            locationID: null,
            locationSelected: false,
            msg: null,
            refreshConsignments: false,
            refreshToggle: false,
        }
    },
    async mounted() {
        if (this.locationID == null) {
            try {
                this.locationID = this.selectedLocationID();
                if (this.locationID == null) {
                    this.locationID = await this.$selectItem({
                        navigation: 'locations', 
                        itemText: 'locationName', 
                        itemValue: 'id'
                    });
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.locationSelected = true;
                console.log('selected');
            }
        }
    },
    methods: {
        consignmentsLoaded(bladesData, cList) {
            console.log(cList);
            if (this.isArrayOfLength(cList, 1)) {
                //open
                bladesData.openBlade({ bladeName: 'stock-consignment', data: { id: cList[0].id }});
            }
            else {
                bladesData.closeBlade({ bladeName: 'stock-consignment' });
            }
        },
        async fulfill(item, bladesData) {
            try {
                this.loadingMsg = 'Fulfilling Delivery';

                await this.$BlitzIt.store.patch('courier-movements', { id: item.id, rowVersion: item.rowVersion, isFulfilledRequest: true });
                
                item.isFulfilled = true;

                this.$BlitzIt.store.deleteLocal('courier-movements', item.id);

                this.refreshToggle = !this.refreshToggle;

                bladesData.closeBlade({ bladeName: 'courier-movement' });
                bladesData.closeBlade({ bladeName: 'stock-consignment' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        movementLoaded() {
            this.refreshConsignments = !this.refreshConsignments;
        }
    }
}
</script>