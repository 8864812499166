var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.locationSelected)?_c('BT-Blades',{attrs:{"anchorBlades":[{ bladeName: 'courier-movements' }]},scopedSlots:_vm._u([{key:"default",fn:function(bladesData){return [_c('BT-Blade-List',{attrs:{"addBladeName":"courier-movement","bladeName":"courier-movements","bladesData":bladesData,"canClose":false,"dense":"","getParams":function () { return { filterBy: 'Flagged,With No Journeys', departureLocationID: _vm.locationID } },"navigation":"courier-movements","refreshToggle":_vm.refreshToggle,"title":"Flagged Deliveries"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.bundleName)+_vm._s(item.bundleName != null ? ' - ' : null)+_vm._s(item.destinationLocation.companyAccount.companyName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toLocationLine")(item.destinationLocation)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toDayShortDate")(item.dueArrivalOn)))])],1)],1)]}}],null,true)}),_c('BT-Blade-Item',{attrs:{"bladesData":bladesData,"bladeName":"courier-movement","canDelete":false,"canEdit":false,"canMinimize":false,"canPin":false,"navigation":"courier-movement","title":"Delivery","defaultBladeWidth":400},on:{"fetched":_vm.movementLoaded},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',[_c('v-img',{staticClass:"mx-auto",attrs:{"src":_vm.companyLogoURL(item.buyerID)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")])]},proxy:true}],null,true)})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.buyer.companyName)+" ")])],1),(item.deliveredOn != null)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Delivered On")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("toShortDateAndTime")(item.deliveredOn)))])],1),_c('v-list-item-action',[_c('BT-Signature-Overlay',{attrs:{"buttonClass":"primary","canEdit":false,"color":"white","text":"Proof"},model:{value:(item.proofOfDelivery),callback:function ($$v) {_vm.$set(item, "proofOfDelivery", $$v)},expression:"item.proofOfDelivery"}})],1)],1):_vm._e(),(item.deliveredByUserID != null)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Delivered By")]),_c('v-list-item-title',[_c('BT-Entity',{attrs:{"navigation":"users","itemText":"userName","itemValue":item.deliveredByUserID}})],1)],1)],1):_vm._e(),_c('BT-Field-String',{attrs:{"label":"Driver Notes"},model:{value:(item.driverNotes),callback:function ($$v) {_vm.$set(item, "driverNotes", $$v)},expression:"item.driverNotes"}}),(!item.isFulfilled && (item.isPickup && item.releasedOn != null || item.deliveredOn != null))?_c('v-list-item',[_c('v-list-item-content',[_c('BT-Blade-Button',{attrs:{"label":"Manually Fulfill","icon":"mdi-check-circle-outline"},on:{"click":function($event){return _vm.fulfill(item, bladesData)}}})],1)],1):_vm._e(),(_vm.isLengthyArray(item.packagedItems))?_c('BT-List-Endless',{attrs:{"canSearch":false,"dividers":false,"items":item.packagedItems,"label":"Packages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_vm._v(_vm._s(_vm._f("toDisplayNumber")(item.quantityDelivered)))]),_c('v-list-item-content',[_c('v-list-item-title',[_c('BT-Entity',{attrs:{"navigation":"measurements","itemValue":item.measurementID,"itemText":"measurementName"}})],1)],1)]}}],null,true)}):_vm._e(),_c('BT-List-Endless',{attrs:{"canSearch":false,"customURL":'/get/GetConsignments/' + item.id,"dividers":false,"getParams":function () { return { includeDetails: false }; },"label":"Consignments","navigation":"releases","refreshToggle":_vm.refreshConsignments},on:{"loaded":function (cList) { _vm.consignmentsLoaded(bladesData, cList); }},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){return bladesData.openBlade({ bladeName: 'stock-consignment', data: { id: item.id } })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("toFamiliarLocationLine")(item.destinationLocation)))]),_c('v-list-item-subtitle',[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_vm._v(" #"+_vm._s(item.consignmentNumber)+" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm._f("toDisplayNumber")(item.orderWeight))+" KG ")],1)],1)],1)],1)]}}],null,true)})]}}],null,true)}),_c('Stock-Consignment-Blade',{attrs:{"bladesData":bladesData}})]}}],null,false,2083383029)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }